import ProfileService from "../services/profile.service";

export const fetchProfileData = async (accessToken) => {
  const profileService = new ProfileService();
  let profile = await profileService.getUserProfile(accessToken);
  return profile;
}

export const fetchB2PUserData = async (accessToken) => {
  const profileService = new ProfileService();
  let profile = await profileService.getUserB2PProfile(accessToken);
  return profile?.info;
}