import React, { useEffect } from 'react';
import { COLOR_PURPLE } from '../../constants/appConst'
import { Button, Box } from '@mui/material';
import { 
  ParkifyLogoSB, 
  // AnalyticsIconSB, 
  ParkingIconSB, 
  DealsIconSB, 
  SettingsIconSB 
} from '../../layouts/SVGs';
import { useLocation, Link } from "react-router-dom";
import {
  LinkName,
  SidebarTab
} from './styles'
import { fetchB2PUserData } from '../../thunks';
import { useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../utils/redux/registerReducer/actions';
import { setProfileData } from '../../utils/redux/apiCacheReducer/actions';
import { fetchProfileData } from '../../thunks'

function Sidebar(props) {
  const menu = [
    // {href: '/analytics', name: 'Аналитика', icon: <AnalyticsIconSB />},
    {href: '/parking', name: 'Парковки', icon: <ParkingIconSB />},
    {href: '/deals', name: 'Сделки', icon: <DealsIconSB />},
    {href: '/settings', name: 'Настройки', icon: <SettingsIconSB />},
  ];
  const location = useLocation();
  const pathname = (location?.pathname || '/').split('/').filter(item => item.length)[0];

  const dispatch = useDispatch();
  const {accessToken} = useAuth();
  let navigate = useNavigate();
  
  const onLogout = () => {
    if(accessToken) {
      dispatch(logout())
    }
    const route = "/login";
    navigate(route);
  }

  const cachedProfileData = useSelector((state) => state.apiCacheReducer.profileData);

  useEffect(() => {
    let nickname, avatar;

    if (Object.values(cachedProfileData).some(Boolean)) {
      ({ nickname, avatar } = cachedProfileData);
    } else {
      fetchProfileData(accessToken).then(data => {
        if(!data) return;
        ({ nickname, avatar } = data);
        nickname = nickname || data.email;
        dispatch(setProfileData({ nickname, avatar }));
      })
    }
  }, [accessToken, cachedProfileData, dispatch])

  return (
      <Box sx={{ background: COLOR_PURPLE, height: '100vh', position: 'fixed', width: '15vw'  }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '15%' }}>
          <Link to={'/adding-parking-spaces'}> 
            <ParkifyLogoSB />
          </Link>
        </Box>

        <Box>
          {menu.map(({href, name, icon}, index) => (
            <SidebarTab key={`tab_${index}`} selected={href.includes(pathname)}>
              <Link to={href} style={{textDecoration: 'none'}}>
              <Box sx={{
                display: 'inline-block',
                position: 'relative',
              }}>
                {icon}
              </Box>
              <LinkName>{name}</LinkName> 
              </Link>
            </SidebarTab>
          ))}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: '5vh',
            left: '50%',
            transform: 'translateX(-50%)'
          }}  
        >
          <Button 
            color="inherit"
            disableRipple
            sx={{
              color: "#fff",
              fontFamily: 'Helvetica Neue, Roboto, Arial, sans-serif;',
              fontSize: '0.72vw',
              fontWeight: '400',
              letterSpacing: '0.25vw',
              '&:hover': {
                backgroundColor: 'unset',
              },
            }}
            onClick={onLogout}
          >ВЫХОД</Button>
        </Box>
    
      </Box>
  );
}

export default Sidebar;